<template>
  <modal>
    <div class="my-auto w-full mx-12 p-12">
      <div class="text-center mb-10">
        <h1>Sign in</h1>
        <p class="text-gray-500">
          Access our fabulous reseller portal
        </p>
      </div>
      <form @submit.prevent="login" @keydown="form.onKeydown($event)">
        <!-- Email -->
        <div class="form-group row">
          <label>{{ $t('email') }}</label>
          <input
            v-model="form.email"
            :class="{ 'is-invalid': form.errors.has('email') }"
            class="form-control"
            type="email"
            name="email"
            placeholder="your@email.com"
          >
          <has-error :form="form" field="email" />
        </div>

        <!-- Password -->
        <div class="form-group">
          <div class="flex">
            <label>{{ $t('password') }}</label>
            <router-link
              :to="{ name: 'password.request' }"
              class="text-xs ml-auto my-auto text-gray-500"
            >
              {{ $t('forgot_password') }}
            </router-link>
          </div>
          <input
            v-model="form.password"
            :class="{ 'is-invalid': form.errors.has('password') }"
            class="form-control"
            type="password"
            name="password"
            placeholder="Enter your password"
          >
          <has-error :form="form" field="password" />
        </div>

        <!-- Remember Me -->
        <div class="form-group">
          <div class="flex">
            <label>
              <input v-model="remember" type="checkbox" name="remember">
              <span class="text-sm">{{ $t('remember_me') }}</span>
            </label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-7 offset-md-3 d-flex">
            <!-- Submit Button -->
            <button class="btn w-full">
              {{ $t('login') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import Form from 'vform'

export default {
  layout: 'login',

  components: {},

  metaInfo () {
    return { title: this.$t('login') }
  },

  data: () => ({
    form: new Form({
      email: '',
      password: ''
    }),
    remember: false
  }),

  methods: {
    async login () {
      // Submit the form.
      const { data } = await this.form.post(this.$apiUrl.login)

      // Save the token.
      this.$store.dispatch('auth/saveToken', {
        token: data.token,
        remember: this.remember
      })

      // Fetch the user.
      await this.$store.dispatch('auth/fetchUser')

      // Redirect home.
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
